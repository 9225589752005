import BgzlCard from './BgzlCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import BgzlListUtil ,{IBgzlListDataObj} from './bgzlListUtil';
import DzzlCard from "@/views/project/order/fy/zl/dzzl/DzzlCard.vue";
export default defineComponent ({
    name: 'BgzlList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IBgzlListDataObj=reactive<IBgzlListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: BgzlCard,
                cardFrom:'BgzlList',
                modelMethod: utils.OrderProviderApi.buildUrl('/bgzl/pageData')
            },
            otherParams:{
                selectRow:{},//清关选择的行
                visible:false,//清关弹出框显示/隐藏控制字段
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BgzlListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'未提交'},{value:1,label:'已提交'},{value:2,label:'已清关'}]
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.selectRow['F_ID'],
                    fileSize:80,
                    accept:'.pdf',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看资料
        const viewZlHandler=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,modelComp:DzzlCard,ownerComp:dataObj.pageListRef,
                bgzlId:row.F_ID,
                cardFrom:'BgzlList',overflow:'hidden',title: '资料汇总',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //清关
        const qgHandler=()=>{
            let selections:Array<any>=dataObj.pageListRef.getTbInst().getSelection();
            if(selections.length==0){
                proxy.$message('请选择要操作的行');
                return ;
            }
            dataObj.otherParams.selectRow=selections[0];
            dataObj.otherParams.visible=true;
            nextTick(()=>{
                dataObj.refMap.get('qgdj').uploadParams.belongMaxId=dataObj.otherParams.selectRow['F_ID'];
            })
        }
        //确认清关
        const sureQgHandler=async ()=>{
            if(dataObj.refMap.get('qgdj').fileList.length==0) {
                proxy.$message({type:'error',message:'至少上传一份清关单据'});
                return false;
            }else{
                await dataObj.utilInst.doQg();
            }
        }
        return{
            ...toRefs(dataObj),comboSelect,buildUploadInitParams,handlePreview,formatPageInfo,viewZlHandler,qgHandler,sureQgHandler
        }
    }
});